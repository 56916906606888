import { globalQueryKeys } from '../global-query-keys';

export const DEVICE_MODELS_API_URL = 'ui/partner/device_models';

export function getDeviceModelApiUrl(deviceModelId: string) {
  return `${DEVICE_MODELS_API_URL}/${deviceModelId}`;
}

export const deviceModelsQueryKeys = {
  base: [DEVICE_MODELS_API_URL],
  detail: (deviceModelId: string) => [
    ...deviceModelsQueryKeys.base,
    deviceModelId,
  ],
  hardwareKeys: {
    base: ['hardwareKeys'],
    all: () => [
      ...deviceModelsQueryKeys.base,
      ...deviceModelsQueryKeys.hardwareKeys.base,
    ],
    details: (modelId: string) => [
      ...deviceModelsQueryKeys.detail(modelId),
      ...deviceModelsQueryKeys.hardwareKeys.base,
    ],
  },
  apiVersions: {
    all: () => [...deviceModelsQueryKeys.base, 'apiVersions'],
  },
  widgets: {
    all: (deviceModelId: string) => [
      ...deviceModelsQueryKeys.detail(deviceModelId),
      'widgets',
    ],
    new: (deviceModelId: string) => [
      ...deviceModelsQueryKeys.widgets.all(deviceModelId),
      'new',
    ],
  },
  sections: {
    all: (deviceModelId: string) => [
      ...deviceModelsQueryKeys.detail(deviceModelId),
      'sections',
    ],
  },
  supportedCommands: {
    base: ['supportedCommands'],
    all: (deviceModelId: string) => [
      ...deviceModelsQueryKeys.detail(deviceModelId),
      ...deviceModelsQueryKeys.supportedCommands.base,
    ],
  },
  files: {
    global: [...globalQueryKeys.files],
    all: (deviceModelId: string) => [
      ...deviceModelsQueryKeys.files.global,
      ...deviceModelsQueryKeys.detail(deviceModelId),
    ],
  },
  testDevices: {
    all: (deviceModelId: string) => [
      ...deviceModelsQueryKeys.detail(deviceModelId),
      'test-devices',
    ],
  },
  subModels: {
    all: (deviceModelId: string) => [
      ...deviceModelsQueryKeys.detail(deviceModelId),
      'sub-models',
    ],
  },
};
