import { createStyles, Group } from '@mantine/core';
import React from 'react';

import { SearchInput } from '@portals/core';
import { usePermissionAccess } from '@portals/framework';

import { CreateCustomerSpaceButton } from './CreateCustomerSpaceButton';

interface TreeHeaderProps {
  handleSearchOnChange: (value: string) => void;
  searchString: string;
  isReadonly?: boolean;
}

export function TreeHeader({
  handleSearchOnChange,
  searchString,
  isReadonly = false,
}: TreeHeaderProps) {
  const { classes } = useStyles();
  const { isAdmin } = usePermissionAccess();

  const displayCreateCustomButton = isAdmin && !isReadonly;

  return (
    <Group px="md">
      <SearchInput
        value={searchString}
        placeholder="Find space..."
        onClear={() => handleSearchOnChange('')}
        onChange={(event) => handleSearchOnChange(event.target.value)}
        size="md"
        w="100%"
        classNames={{
          root: classes.inputRoot,
          input: classes.input,
        }}
      />

      {displayCreateCustomButton && <CreateCustomerSpaceButton />}
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  inputRoot: {
    height: 40,
  },

  input: {
    minHeight: 40,
    height: 40,
    borderColor: theme.colors.gray[3],
  },
}));
