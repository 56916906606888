import { NonNormalizedSpaceType } from './spaces.types';

export function normalizeSpacePath(space: NonNormalizedSpaceType) {
  const normalizedPath =
    space.path === ''
      ? []
      : space.path.split('.').map((pathId) => parseInt(pathId));

  return {
    ...space,
    path: normalizedPath,
  };
}
