import { find } from 'lodash/fp';
import { useMemo } from 'react';

import { SpaceType, useSpaces } from '@portals/api/organizations';
import { buildTreeFromNodes } from '@portals/utils';

export const findRoot = (spaces: SpaceType[]) =>
  find({ parent_id: null }, spaces);

export const findAccessible = (spaces: SpaceType[]) =>
  find('access_level', spaces);

export function useSpacesTree() {
  const spaces = useSpaces();

  return useMemo(() => buildTreeFromNodes(spaces.data || []), [spaces.data]);
}

interface UseSpaceTreeParams {
  spaceId: SpaceType['id'] | null | undefined;
  parentSpaceId: SpaceType['parent_id'];
}

export function useSpaceTree({ spaceId, parentSpaceId }: UseSpaceTreeParams) {
  const spaces = useSpaces();

  return useMemo(() => {
    const treeNodes = buildTreeFromNodes(spaces.data || [], parentSpaceId);

    if (!spaceId) {
      return treeNodes[0];
    }

    return treeNodes.filter((space) => space.id === spaceId)[0];
  }, [spaces.data, spaceId, parentSpaceId]);
}

export function findSpaceFirstChild({
  parentSpaceId,
  spaces,
}: {
  parentSpaceId: SpaceType['id'] | undefined;
  spaces: SpaceType[];
}) {
  const spaceFirstChild = spaces.find(
    (space) => space.parent_id === parentSpaceId
  );

  if (!spaceFirstChild) {
    return findRoot(spaces);
  }

  return spaceFirstChild;
}
