import {
  TooltipProps,
  TooltipStylesNames,
  TooltipStylesParams,
} from '@mantine/core';

import { ThemeComponentOverrides } from '../common-types';

export const Tooltip: ThemeComponentOverrides<
  TooltipProps,
  TooltipStylesNames,
  TooltipStylesParams
> = {
  defaultProps: {
    withinPortal: true,
    arrowSize: 8,
    events: { touch: true, hover: true, focus: false },
  },
};
