import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { IntegrationType } from '@portals/types';

import { C2CConnectorType } from './connector.types';
import {
  CONNECTORS_API_URL,
  connectorsQueryKeys,
} from './connectors.constants';
import { useApiQuery } from '../../hooks';
import { QueryOptions, ServerError, ServerSuccess } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export function useConnectors() {
  return useApiQuery<IntegrationType[]>(
    CONNECTORS_API_URL,
    connectorsQueryKeys.all(),
    {
      staleTime: 0,
    }
  );
}

export function useConnectorById(
  integrationId: string,
  queryOptions: QueryOptions<IntegrationType> = {}
) {
  return useApiQuery<IntegrationType>(
    `${CONNECTORS_API_URL}/${integrationId}`,
    connectorsQueryKeys.details(integrationId),
    {
      staleTime: 0,
      ...queryOptions,
    }
  );
}

interface UseCreateNewConnectorParams {
  connectorType: C2CConnectorType;
  spaceId: number;
}

export function useCreateNewConnector() {
  const { url, options } = useRequestOptions({
    url: CONNECTORS_API_URL,
    method: 'POST',
  });

  return useMutation<IntegrationType, ServerError, UseCreateNewConnectorParams>(
    ({ connectorType, spaceId }) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          name: connectorType,
          space_id: spaceId,
        }),
      }),
    {
      meta: {
        mutationName: 'useCreateNewConnector',
        baseUrl: CONNECTORS_API_URL,
        method: 'POST',
      },
      onError: (error) => {
        toastrError(error?.error);
      },
      onSuccess: () => {
        toastrSuccess('Connector created');
      },
    }
  );
}

export function useDeleteConnector() {
  const { url: baseUrl, options } = useRequestOptions({
    url: CONNECTORS_API_URL,
    method: 'DELETE',
  });

  return useMutation<ServerSuccess, ServerError, string>(
    (integrationId) => fetchApiRequest(`${baseUrl}/${integrationId}`, options),
    {
      meta: {
        mutationName: 'useDeleteConnector',
        baseUrl: `${CONNECTORS_API_URL}/:id`,
        method: 'POST',
      },
      onError: (error) => {
        toastrError(error?.error);
      },
      onSuccess: () => {
        toastrSuccess('Connector removed');
      },
    }
  );
}

interface UseSetConnectorParams {
  connectorParams: any;
  connectorId: string;
}

export function useSetConnectorParams() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: CONNECTORS_API_URL,
    method: 'PUT',
  });

  return useMutation<IntegrationType, ServerError, UseSetConnectorParams>(
    ({ connectorId, connectorParams }) =>
      fetchApiRequest(`${url}/${connectorId}/set_params`, {
        ...options,
        body: JSON.stringify(connectorParams),
      }),
    {
      meta: {
        mutationName: 'useSetConnectorParams',
        baseUrl: `${CONNECTORS_API_URL}/:id/set_params`,
        method: 'PUT',
      },
      onSettled: (response, error, params) => {
        queryClient.refetchQueries(connectorsQueryKeys.all());
        queryClient.refetchQueries(
          connectorsQueryKeys.details(params?.connectorId)
        );
      },
      onError: (error) => {
        toastrError(error?.error);
      },
      onSuccess: () => {
        toastrSuccess('Connector updated');
      },
    }
  );
}

export function useDisableConnector() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: CONNECTORS_API_URL,
    method: 'PUT',
  });

  return useMutation<IntegrationType, ServerError, string>(
    (connectorId) => fetchApiRequest(`${url}/${connectorId}/disable`, options),
    {
      meta: {
        mutationName: 'useDisableConnector',
        baseUrl: `${CONNECTORS_API_URL}/:id`,
        method: 'PUT',
      },
      onSettled: (response, error, connectorId) => {
        queryClient.refetchQueries(connectorsQueryKeys.all());
        queryClient.refetchQueries(connectorsQueryKeys.details(connectorId));
      },
      onError: (error) => {
        toastrError(error?.error);
      },
      onSuccess: () => {
        toastrSuccess('Connector disabled');
      },
    }
  );
}

export function useEnableConnector() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: CONNECTORS_API_URL,
    method: 'PUT',
  });

  return useMutation<IntegrationType, ServerError, string>(
    (connectorId) => fetchApiRequest(`${url}/${connectorId}/enable`, options),
    {
      meta: {
        mutationName: 'useEnableConnector',
        baseUrl: `${CONNECTORS_API_URL}/:id`,
        method: 'PUT',
      },
      onSettled: (response, error, connectorId) => {
        queryClient.refetchQueries(connectorsQueryKeys.all());
        queryClient.refetchQueries(connectorsQueryKeys.details(connectorId));
      },
      onError: (error) => {
        toastrError(error?.error);
      },
      onSuccess: () => {
        toastrSuccess('Connector enabled');
      },
    }
  );
}
