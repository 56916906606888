import { Box, createStyles, LoadingOverlay, px } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React, { Dispatch, SetStateAction, useState } from 'react';

import { SpaceType } from '@portals/api/organizations';
import { expandNodesByDepth } from '@portals/utils';

import { useSpaceTree } from '../../../lib/spaces';
import {
  type SpacesTreeProps,
  Tree,
} from '../../pages/overview/overview-sidebar/organization-tree/Tree';
import { CustomLabel } from '../../pages/overview/overview.types';

interface SpaceTreeProps {
  selectedSpaceId: SpaceType['id'] | null;
  setSelectedSpaceId: Dispatch<SetStateAction<SpaceType['id'] | null>>;
  customLabel?: CustomLabel;
  rootCustomerSpace?: SpaceType;
}

const ITEM_HEIGHT = 40;

export function OrganizationTree({
  selectedSpaceId,
  setSelectedSpaceId,
  customLabel,
  rootCustomerSpace,
}: SpaceTreeProps) {
  const { classes, theme } = useStyles();
  const [searchTerm, setSearchTerm] = useState('');

  const spaceTree = useSpaceTree({
    spaceId: rootCustomerSpace?.id,
    parentSpaceId: rootCustomerSpace?.parent_id ?? null,
  });

  const [expandedNodes, setExpandedNodes] = useState(
    expandNodesByDepth([spaceTree] || [], 3)
  );

  const onSelect: SpacesTreeProps['handleSelected'] = (spaceId) => {
    setSelectedSpaceId(spaceId);
  };

  return (
    <Box className={classes.container}>
      <LoadingOverlay visible={isEmpty([spaceTree])} />

      {!isEmpty([spaceTree]) ? (
        <Tree
          readonly
          draggable={false}
          itemHeight={ITEM_HEIGHT}
          indent={px(theme.spacing.sm)}
          handleSelected={onSelect}
          itemIdPrefix="move-device"
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          treeNodes={[spaceTree]}
          selectedSpaceId={selectedSpaceId}
          expandedNodes={expandedNodes}
          setExpandedNodes={setExpandedNodes}
          customLabel={customLabel}
          treeHeight={380}
        />
      ) : null}
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',

    '.organization-tree-container': {
      padding: 0,

      '.rc-tree-list-holder': {
        paddingRight: theme.spacing.sm,

        '.tree-item': {
          '.tree-item-indicators': {
            display: 'none',
          },
        },
      },
    },
  },
  portal: {
    color: theme.colors.gray[6],
  },
  overlay: {
    cursor: 'not-allowed',
    right: -theme.spacing.sm,
  },
}));
