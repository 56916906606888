import { Button, ButtonProps } from '@mantine/core';
import React, { forwardRef, useCallback } from 'react';

import {
  useSendPartnerSupportEmail,
  UseSendSupportEmailParams,
} from '@portals/api/organizations';
import { ContactSupportModalProps } from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';

interface ContactPartnerSupportButtonProps extends ButtonProps {
  supportModalTitle: string;
}

export const ContactPartnerSupportButton = forwardRef<
  HTMLButtonElement,
  ContactPartnerSupportButtonProps
>(({ supportModalTitle, children, ...buttonProps }, ref) => {
  const openContactPartnerSupportModal = useOpenContactPartnerSupportModal();

  return (
    <Button
      ref={ref}
      color="blue_gray.8"
      variant="subtle"
      {...buttonProps}
      onClick={() => openContactPartnerSupportModal(supportModalTitle)}
      data-testid="contact-support-button"
    >
      {children || 'Contact support'}
    </Button>
  );
});

export function useOpenContactPartnerSupportModal(params?: {
  onOpenContactSupportModal?: VoidFunction;
}) {
  const openModal = useOpenModal();

  const { mutate: sendPartnerSupportEmail } = useSendPartnerSupportEmail();

  const onSendSupportEmail = useCallback(
    (values: UseSendSupportEmailParams) => {
      sendPartnerSupportEmail(
        {
          title: values.title,
          message: values.message,
        },
        {
          onSuccess: () => {
            openModal('ContactSupportSuccessModal');
          },
        }
      );
    },
    [openModal, sendPartnerSupportEmail]
  );

  return useCallback(
    (title?: string, message?: string) => {
      params?.onOpenContactSupportModal?.();

      openModal<ContactSupportModalProps['data']>('ContactSupportModal', {
        onSubmit: onSendSupportEmail,
        title,
        message,
      });
    },
    [onSendSupportEmail, openModal, params]
  );
}
