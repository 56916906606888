import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useState } from 'react';

import { ServerError } from '@portals/api';
import { useSetConnectorParams } from '@portals/api/organizations';
import { ModalProps } from '@portals/framework';
import { IntegrationType } from '@portals/types';

export interface BrightSignConnectorSettingsModalProps
  extends ModalProps<{
    connector: IntegrationType;
  }> {}

export function BrightSignConnectorSettingsModal({
  closeMe,
  data,
}: BrightSignConnectorSettingsModalProps) {
  const [networksList, setNetworksList] = useState(null);
  const setConnectorParams = useSetConnectorParams();
  const [error, setError] = useState<ServerError | null>(null);

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      network: '',
    },
  });

  function onAuthorize(values: typeof form.values) {
    if (!networksList) {
      setError(null);

      setConnectorParams.mutate(
        {
          connectorId: data.connector.id,
          connectorParams: {
            email: values.email,
            password: values.password,
          },
        },
        {
          onSuccess: (connector) => {
            setNetworksList(connector.extra.networks);
          },
          onError: (error) => {
            setError(error);
          },
        }
      );
    } else {
      setError(null);

      setConnectorParams.mutate(
        {
          connectorId: data.connector.id,
          connectorParams: {
            network: values.network,
            import: true,
          },
        },
        {
          onSuccess: () => {
            closeMe();
          },
          onError: (error) => {
            setError(error);
          },
        }
      );
    }
  }

  return (
    <Modal
      radius="lg"
      onClose={closeMe}
      opened={true}
      size="lg"
      title="BrightSign Authentication"
      styles={(theme) => ({
        content: {
          padding: theme.spacing.xxl,
        },
      })}
    >
      <LoadingOverlay visible={setConnectorParams.isLoading} />

      <Stack>
        <Text size="sm">
          Please enter the credentials for the customer's BSN.cloud Control
          Cloud account.
        </Text>

        <form onSubmit={form.onSubmit(onAuthorize)}>
          <Stack spacing="xxl">
            {networksList ? (
              <Select
                label="Network"
                placeholder="Select network"
                data={networksList}
                withinPortal
                dropdownPosition="bottom"
                {...form.getInputProps('network')}
              />
            ) : (
              <Stack>
                <TextInput
                  w="100%"
                  data-autofocus
                  required
                  {...form.getInputProps('email')}
                  label="Email"
                  placeholder="Enter email"
                />

                <TextInput
                  w="100%"
                  required
                  type="password"
                  label="Password"
                  placeholder="Enter password"
                  {...form.getInputProps('password')}
                />
              </Stack>
            )}

            {error && (
              <Text color="red" size="sm">
                {error.error}
              </Text>
            )}

            <Group position="right">
              <Button onClick={closeMe} variant="default">
                Cancel
              </Button>
              <Button type="submit">Authorize</Button>
            </Group>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
}
