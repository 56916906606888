import {
  appspaceLogo,
  assaLogo,
  avigilonLogo,
  biampLogo,
  brightSignLogo,
  brivoLogo,
  controlbywebLogo,
  domotzLogo,
  fourSuitesLogo,
  honeywellLogo,
  lenelLogo,
  mtrLogo,
  neatLogo,
  novastarLogo,
  polyLogo,
  ptiLogo,
  qSysLogo,
  saltoLogo,
  sonosLogo,
  twoNLogo,
  viewsonicLogo,
  visionlineLogo,
  webexLogo,
  xioLogo,
  yaleLogo,
  yealinkLogo,
  zoomLogo,
} from '@portals/assets';
import { DataType } from '@portals/types';

export type C2CIntegrationId =
  | keyof DataType['integrations']
  | 'webex'
  | 'poly'
  | 'neat'
  | 'novastar'
  | 'yale'
  | '2n'
  | 'honeywell'
  | 'lenel'
  | 'assa'
  | 'visionline'
  | 'avigilon'
  | 'brivo'
  | 'controlbyweb'
  | '4suites'
  | 'pti'
  | 'salto'
  | 'biamp'
  | 'sonos'
  | 'viewsonic'
  | 'yealink'
  | 'domotz'
  | 'appspace';

export interface C2CIntegrationItem {
  id: C2CIntegrationId;
  logo: string;
  name: string;
  description: string;
}

export const C2C_INTEGRATIONS = {
  mtr: {
    id: 'mtr',
    name: 'Microsoft Teams Rooms',
    logo: mtrLogo,
    description:
      'Microsoft Teams Rooms optimize meetings with integrated video conferencing, making collaboration in workspaces more efficient and engaging.',
  },
  zoom: {
    id: 'zoom',
    name: 'Zoom Rooms',
    logo: zoomLogo,
    description:
      'Zoom Rooms deliver seamless video conferencing experiences, transforming any space into a modern, collaborative meeting environment.',
  },
  xio: {
    id: 'xio',
    name: 'Crestron XiO Cloud',
    logo: xioLogo,
    description:
      'Crestron XiO Cloud provides remote management and monitoring of devices, streamlining IT operations across workspaces.',
  },
  q_sys: {
    id: 'q_sys',
    name: 'Reflect',
    logo: qSysLogo,
    description:
      'QSC Reflect offers cloud-based management and monitoring for AV systems, ensuring reliable performance and easy control in various environments.',
  },
  bright_sign: {
    id: 'bright_sign',
    name: 'BrightSign',
    logo: brightSignLogo,
    description:
      "BrightSign is a digital signage platform that allows for remote access and control of multimedia content via integration with a cloud IoT provider. This is done using BrightSign's built-in networking and APIs. Specific integration steps depend on the cloud IoT provider.",
  },
  webex: {
    id: 'webex',
    name: 'Cisco Webex',
    logo: webexLogo,
    description:
      'Cisco Webex provides a robust video conferencing solution, turning any room into a connected, collaborative meeting environment.',
  },
  poly: {
    id: 'poly',
    name: 'Poly',
    logo: polyLogo,
    description:
      'Poly enables immersive video conferencing, bringing modern collaboration experiences to any meeting space.',
  },
  neat: {
    id: 'neat',
    name: 'Neat',
    logo: neatLogo,
    description:
      'Neat redefines video conferencing, creating seamless, intuitive communication experiences in any room.',
  },
  novastar: {
    id: 'novastar',
    name: 'Novastar',
    logo: novastarLogo,
    description:
      'Novastar revolutionizes LED display technology, transforming spaces into visually stunning, collaborative environments.',
  },
  yale: {
    id: 'yale',
    name: 'Yale',
    logo: yaleLogo,
    description:
      'Yale delivers smart security solutions, making any building safer and more connected.',
  },
  '2n': {
    id: '2n',
    name: '2N',
    logo: twoNLogo,
    description:
      '2N offers advanced intercom systems, transforming building communication with cutting-edge security and control.',
  },
  honeywell: {
    id: 'honeywell',
    name: 'Honeywell',
    logo: honeywellLogo,
    description:
      'Honeywell provides integrated security solutions, ensuring safety and seamless building management.',
  },
  lenel: {
    id: 'lenel',
    name: 'Lenel',
    logo: lenelLogo,
    description:
      'Lenel delivers powerful security systems, modernizing access control and enhancing building safety.',
  },
  assa: {
    id: 'assa',
    name: 'Assa',
    logo: assaLogo,
    description:
      'Assa offers innovative locking systems, transforming any facility into a secure, smart environment.',
  },
  visionline: {
    id: 'visionline',
    name: 'ASSA ABLOY Visionline',
    logo: visionlineLogo,
    description:
      'ASSA ABLOY Visionline offers advanced access control, modernizing building security with seamless integration.',
  },
  avigilon: {
    id: 'avigilon',
    name: 'Avigilon Access Control',
    logo: avigilonLogo,
    description:
      'Avigilon provides cutting-edge access control systems, transforming any facility into a secure, streamlined environment.',
  },
  brivo: {
    id: 'brivo',
    name: 'Brivo Access Control',
    logo: brivoLogo,
    description:
      'Brivo delivers cloud-based access control, making any building safer and more connected.',
  },
  controlbyweb: {
    id: 'controlbyweb',
    name: 'ControlByWeb',
    logo: controlbywebLogo,
    description:
      'ControlByWeb offers advanced web-based monitoring, transforming facility management with real-time control and visibility.',
  },
  '4suites': {
    id: '4suites',
    name: '4Suites Locks',
    logo: fourSuitesLogo,
    description:
      '4Suites provides smart lock solutions, making any facility secure and effortlessly connected.',
  },
  pti: {
    id: 'pti',
    name: 'PTI Security Systems',
    logo: ptiLogo,
    description:
      'PTI delivers advanced security systems, transforming storage facilities into secure, controlled environments.',
  },
  salto: {
    id: 'salto',
    name: 'Salto Locks',
    logo: saltoLogo,
    description:
      'Salto offers smart locking solutions, revolutionizing access control in any modern facility.',
  },
  domotz: {
    id: 'domotz',
    name: 'Domotz',
    logo: domotzLogo,
    description:
      'Domotz provides powerful network monitoring solutions, transforming any environment into a secure, connected, and efficiently managed space.',
  },
  biamp: {
    id: 'biamp',
    name: 'Biamp',
    logo: biampLogo,
    description:
      'Biamp offers world-class audio systems, transforming any space into a clear, connected communication hub.',
  },
  sonos: {
    id: 'sonos',
    name: 'Sonos',
    logo: sonosLogo,
    description:
      'Enables businesses to connect their audio system with the Xyte platform to enhance sound management, device control, and user experience.',
  },
  viewsonic: {
    id: 'viewsonic',
    name: 'ViewSonic',
    logo: viewsonicLogo,
    description:
      'ViewSonic allows businesses to connect their display technology with the Xyte platform to enhance visual communications, device control, and presentations.',
  },
  yealink: {
    id: 'yealink',
    name: 'Yealink',
    logo: yealinkLogo,
    description:
      'Yealink provides intuitive video conferencing solutions, turning any room into a collaborative meeting environment.',
  },
  appspace: {
    id: 'appspace',
    name: 'Appspace',
    logo: appspaceLogo,
    description:
      'Appspace provides workplace management and communication solutions, streamlining collaboration and productivity in hybrid work environments.',
  },
} as const satisfies Partial<Record<C2CIntegrationId, C2CIntegrationItem>>;
