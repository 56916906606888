import {
  Anchor,
  Button,
  createStyles,
  Group,
  Modal,
  ModalBaseStylesNames,
  Stack,
  Styles,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

import { DeviceType, useCreateIncident } from '@portals/api/organizations';
import { ModalFooter } from '@portals/core';
import { DeviceAvatar, ModalProps } from '@portals/framework';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { IncidentPriorityLevel } from '@portals/types';

import { PriorityLevelSelect } from '../components/PriorityLevelSelect';

export interface ReportIncidentModalProps
  extends ModalProps<{ device: DeviceType }> {}

export function ReportIncidentModal({
  data: { device },
  closeMe,
}: ReportIncidentModalProps) {
  const { classes } = useStyles();
  const createIncident = useCreateIncident();

  const form = useForm({
    initialValues: {
      title: '',
      description: '',
      priority: 3 as IncidentPriorityLevel,
    },
  });

  const handleSubmit = (formData: typeof form.values) => {
    createIncident.mutate(
      {
        deviceId: device.id,
        incident: formData,
      },
      {
        onSuccess: closeMe,
      }
    );
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      styles={modalStyles}
      title={
        <Group>
          <Danger />
          Report Incident
        </Group>
      }
    >
      <Group spacing="xl" align="flex-start">
        <DeviceAvatar
          src={device.image_url}
          icon={device.model_settings?.icon}
          size={97}
        />

        <Stack spacing="xs">
          <Title order={6} fw={400} color="blue_gray.6">
            {device.partner.vendor}
          </Title>
          <Title order={4} sx={{ fontWeight: 400 }}>
            {device.partner.model}
          </Title>
        </Stack>
      </Group>
      <div>
        <Text color="blue_gray.6">
          Incidents are used to flag an issue with a device. Note: Incidents may
          also be created automatically by the device based on events or
          manufacturer-defined rules.
        </Text>
        <Anchor href="https://docs.xyte.io/docs/incidents" target="_blank">
          Learn more
        </Anchor>
      </div>
      <form onSubmit={form.onSubmit(handleSubmit)} className={classes.form}>
        <PriorityLevelSelect
          {...form.getInputProps('priority')}
          label="Priority"
          required
          w="30%"
          data-testid="priority-dropdown"
        />
        <TextInput
          {...form.getInputProps('title')}
          label="Title"
          placeholder="General description"
          required
          data-testid="title-input"
        />
        <Textarea
          {...form.getInputProps('description')}
          label="Description"
          placeholder="Detailed description of the problem"
          minRows={8}
          className={classes.incidentDetails}
          data-testid="description-input"
        />
        <ModalFooter grow className={classes.buttonsContainer}>
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>
          <Button
            disabled={!form.isValid()}
            loading={createIncident.isLoading}
            type="submit"
            data-testid="report-incident-button"
          >
            Report incident
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  deviceDetailsContainer: {
    gap: theme.spacing.xl,
    width: '100%',
    alignSelf: 'center',
    margin: 0,
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xl,
  },
  incidentDetails: {
    marginBottom: theme.spacing.xs,
  },
  buttonsContainer: {
    display: 'flex',
    marginInline: '-2rem',
    paddingInline: '2rem',
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));

const modalStyles: Styles<ModalBaseStylesNames> = (theme) => ({
  header: {
    justifyContent: 'flex-start',
    padding: theme.spacing.xl,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    marginBottom: theme.spacing.xxl,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xl,
    padding: theme.spacing.xxl,
  },
});
