import {
  Box,
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  ScrollArea,
  Stack,
  Text,
  Textarea,
} from '@mantine/core';
import React from 'react';

import {
  DeviceModelType,
  useSendPartnerSupportEmail,
} from '@portals/api/organizations';
import { dangerSrc } from '@portals/assets';
import { ModalCenteredMediaLayout } from '@portals/core';
import { getWidgetIcon } from '@portals/device-widgets';
import { ModalProps } from '@portals/framework';

import { ContactPartnerSupportButton } from '../../components/ContactPartnerSupportButton';

export interface DeviceTroubleshootingStepsProps
  extends ModalProps<{ deviceModel: DeviceModelType }> {}

export function DeviceTroubleshootingSteps({
  data,
  closeMe,
}: DeviceTroubleshootingStepsProps) {
  const { classes } = useStyles();
  const sendPartnerSupportEmail = useSendPartnerSupportEmail();

  return (
    <ModalCenteredMediaLayout
      radius="lg"
      onClose={closeMe}
      opened
      title="Device Claiming Failed"
      media={<img src={dangerSrc} width={81} height={81} />}
      mah="80vh"
      styles={{
        root: {
          overflow: 'hidden',
          maxHeight: '90vh',
        },
      }}
      footer={
        <Stack>
          <Text size="sm" align="center" color="blue_gray.9">
            If this issue persists, please contact support for further
            assistance.
          </Text>

          <Group grow noWrap>
            <ContactPartnerSupportButton
              supportModalTitle={`Claiming device: ${data.deviceModel.model}`}
            />

            <Button
              variant="default"
              onClick={closeMe}
              data-testid="device-claim-cancel-button"
            >
              Cancel
            </Button>

            <Button w="100%" onClick={closeMe} data-testid="try-again-button">
              Try again
            </Button>
          </Group>
        </Stack>
      }
    >
      <Stack spacing="xxl">
        <LoadingOverlay visible={sendPartnerSupportEmail.isLoading} />

        <Text size="sm" align="center" color="blue_gray.9">
          We couldn't claim your device. Please check the following and try
          again:
        </Text>

        <ScrollArea.Autosize mah="calc(90vh - 400px)">
          <Stack spacing="xs">
            {data.deviceModel?.troubleshooting_steps?.map((step, index) => {
              const { Icon } = getWidgetIcon(step.icon);

              return (
                <Box key={index} className={classes.stepContainer} w="100%">
                  <Stack align="start">
                    <Icon width={24} height={24} />
                  </Stack>

                  <Stack spacing="xs">
                    <Text color="gray.9" weight={600}>
                      {step.title}
                    </Text>

                    <Textarea
                      color="gray.6"
                      autosize
                      readOnly
                      value={step.description}
                      classNames={{
                        input: classes.textAreaInput,
                      }}
                    />
                  </Stack>
                </Box>
              );
            })}
          </Stack>
        </ScrollArea.Autosize>
      </Stack>
    </ModalCenteredMediaLayout>
  );
}

const useStyles = createStyles((theme) => ({
  stepContainer: {
    borderRadius: theme.radius.lg,
    padding: theme.spacing.md,
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr',
    gap: theme.spacing.md,
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  textAreaInput: {
    border: 'none',
    padding: 0,
    width: '100%',
    borderRadius: 0,
    overflow: 'hidden',
    minHeight: 'unset',
    color: theme.colors.gray[6],
  },
}));
