import { DeviceWidgetsSectionType } from '@portals/device-widgets';

import {
  deviceModelsQueryKeys,
  getDeviceModelApiUrl,
} from './device-models.constants';
import { useApiQuery } from '../../hooks';

function getSectionsApiUrl(modelId: string) {
  return `${getDeviceModelApiUrl(modelId)}/device_sections`;
}

export function useDeviceWidgetsSections(modelId: string) {
  return useApiQuery<Array<DeviceWidgetsSectionType>>(
    getSectionsApiUrl(modelId),
    deviceModelsQueryKeys.sections.all(modelId),
    {
      enabled: !!modelId,
    }
  );
}
