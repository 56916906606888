import { createStyles, Group, Text } from '@mantine/core';
import React from 'react';

import { C2CConnectorType } from '@portals/api/organizations';

import { useIntegrationsList } from '../../../hooks/c2c-integrations';

interface C2cConnectorBadgeProps {
  integrationName: C2CConnectorType;
  onClick: () => void;
  isDisabled?: boolean;
}

export function C2cConnectorBadge({
  integrationName,
  onClick,
  isDisabled,
}: C2cConnectorBadgeProps) {
  const { cx, classes } = useStyles();

  const availableIntegrations = useIntegrationsList();
  const integrationMeta = (availableIntegrations || []).find(
    (integration) => integration.id === integrationName
  );

  if (!integrationMeta) return integrationName;

  return (
    <Group
      className={cx(classes.container, { disabled: isDisabled })}
      noWrap
      onClick={() => (isDisabled ? undefined : onClick())}
    >
      <img
        src={integrationMeta?.logo}
        alt={integrationName}
        width={50}
        height={50}
      />

      <Text>{integrationMeta.name}</Text>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    padding: theme.spacing.xl,
    border: `2px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.lg,
    cursor: 'pointer',
    boxShadow: 'none',

    '&.disabled': {
      cursor: 'not-allowed',
      opacity: 0.8,
    },

    '&:not(.disabled)': {
      '&:hover': {
        boxShadow: '0px 6px 9.1px 0px rgba(0, 0, 0, 0.08)',
        transition: 'box-shadow 0.25s ease-in-out',
      },

      '&:active': {
        borderColor: theme.fn.primaryColor(),
        transition: 'border 0.15s ease-in-out',
      },
    },
  },
}));
