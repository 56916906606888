import { createStyles, Group } from '@mantine/core';
import React from 'react';

import { useSpace } from '@portals/api/organizations';

import { DisabledTreeNode } from './DisabledTreeNode';
import { TreeNode } from './TreeNode';
import { useDeviceDrop } from '../../../../../../../hooks/device-cards';
import { canEdit, canView } from '../../../../../../../lib/access';
import { TreeNodeProps } from '../../../../overview.types';

export function TreeNodeDnDWrapper({
  isDraggable = false,
  node,
  isEditable,
  editModeNodeId,
  setEditModeNodeId,
  customLabel,
  itemIdPrefix,
  handleCreateSpace,
  isInFocus,
  contextMenuPortalRef,
}: TreeNodeProps) {
  const { classes, cx } = useStyles();
  const space = useSpace({ spaceId: node.id });

  const { deviceHover, deviceDroppable, dropRef } = useDeviceDrop({
    node,
    space,
    isDraggable,
    canEdit,
    canView,
  });

  return (
    <Group
      noWrap
      ref={dropRef}
      className={cx(classes.container, {
        'device-hover': deviceHover,
        'device-droppable': deviceDroppable,
      })}
    >
      {canView(node) ? (
        <>
          <TreeNode
            node={node}
            itemIdPrefix={itemIdPrefix}
            isEditable={isEditable}
            editModeNodeId={editModeNodeId}
            setEditModeNodeId={setEditModeNodeId}
            customLabel={customLabel}
            isInFocus={isInFocus}
            contextMenuPortalRef={contextMenuPortalRef}
            handleCreateSpace={handleCreateSpace}
          />

          {isDraggable ? <div className="drop-effect" /> : null}
        </>
      ) : (
        <DisabledTreeNode node={node} itemIdPrefix={itemIdPrefix} />
      )}
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',

    '.drop-effect': {
      position: 'absolute',
      right: 0,
      height: 41,
      left: -12,
      borderRadius: theme.radius.md,
      zIndex: 0,
    },

    '&.device-droppable': {
      '.drop-effect': {
        border: `1.5px dashed ${theme.colors.gray[3]}`,
      },
    },

    '&.device-hover': {
      '.drop-effect': {
        border: `1.5px dashed ${theme.other.primaryColor}`,
      },
    },
  },
}));
