import { Box, createStyles, Flex, Text, TextInput } from '@mantine/core';
import React, { Dispatch, SetStateAction, useCallback } from 'react';

import { SpaceTreeNodeType } from '@portals/api/organizations';
import { NameAbbreviationAvatar } from '@portals/core';

import { CustomLabel } from '../../../../overview.types';
import {
  useIsNodeExpanded,
  useIsNodeSelected,
} from '../../organization-tree.context';

interface TreeNodeLabelProps {
  node: SpaceTreeNodeType;
  customLabel?: CustomLabel;

  // Edit mode
  isEditMode: boolean;
  onFinishEditing: () => Promise<void>;
  spaceNameInput: string;
  onSetSpaceNameInput: Dispatch<SetStateAction<string>>;
}

export function TreeNodeLabel({
  node,
  isEditMode,
  onFinishEditing,
  spaceNameInput,
  onSetSpaceNameInput,
  customLabel,
}: TreeNodeLabelProps) {
  const { classes, cx } = useStyles();
  const isSelected = useIsNodeSelected(node.id);
  const isExpanded = useIsNodeExpanded(node.id);

  const onSearchInputKeyDown = useCallback((event) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      event.target.blur();
    }
  }, []);

  const onSearchInputChange = useCallback(
    (e) => onSetSpaceNameInput(e.target.value),
    [onSetSpaceNameInput]
  );

  // Select all text for easy replacement upon editing mode toggle
  const onRef = useCallback((inputRef: HTMLInputElement) => {
    if (!inputRef) return;

    inputRef.select();
  }, []);

  if (isEditMode) {
    return (
      <Box className={classes.container}>
        <TextInput
          className="inline-space-name-input"
          autoFocus
          ref={onRef}
          value={spaceNameInput}
          onChange={onSearchInputChange}
          onKeyDown={onSearchInputKeyDown}
          onBlur={onFinishEditing}
          classNames={{
            root: classes.inputRoot,
            wrapper: classes.inputWrapper,
          }}
        />
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Flex align="center" gap="xs" className={classes.labelWrapper}>
        {node.space_type === 'customer' && (
          <NameAbbreviationAvatar
            name={node.title}
            radius="xl"
            size={32}
            withToolTip={false}
          />
        )}

        <div
          title={node.title}
          className={cx('node-label', classes.label, {
            selected: isSelected,
            expanded: isExpanded,
          })}
        >
          <NodeLabel node={node} labelRenderer={customLabel} />
        </div>
      </Flex>
    </Box>
  );
}

interface NodeLabelProps {
  node: SpaceTreeNodeType;
  labelRenderer?: CustomLabel;
}

function NodeLabel({ node, labelRenderer }: NodeLabelProps) {
  if (labelRenderer) {
    return labelRenderer({ node });
  }

  return <Text truncate>{node.title}</Text>;
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  labelWrapper: {
    flexGrow: 1,
    overflow: 'hidden',
    maxWidth: '100%',
  },
  label: {
    overflow: 'hidden',
    flexGrow: 1,
  },
  inputRoot: {
    marginRight: theme.spacing.xs,
  },
  inputWrapper: {
    input: {
      border: 'none',
      height: 24,
      minHeight: 24,
      padding: '0 5px',
    },
  },
}));
