import { Loader } from '@mantine/core';
import { noop } from 'lodash/fp';
import React, { useRef } from 'react';
import { useLocalStorage } from 'react-use';

import { DeviceType, SpaceType, useSpace } from '@portals/api/organizations';
import { expandNodesByDepth } from '@portals/utils';

import { Tree } from './components/Tree';
import { useSpaceTree } from '../../../../lib/spaces';

interface SpaceTreeProps {
  spaces: SpaceType[];
  handleSelected: (nodeId: number) => void;
  selected: SpaceType | null;
  readonly?: boolean;
  draggable?: boolean;
  openModal: (type: string, params?: any) => void;
  spaceId?: DeviceType['space_id'];
  hideCreateCustomerSpaceButton?: boolean;
}

export function SpaceTree({
  spaces,
  handleSelected,
  selected,
  readonly,
  spaceId,
  hideCreateCustomerSpaceButton,
  draggable,
}: SpaceTreeProps) {
  const space = useSpace({ spaceId });
  const rootCustomerSpace = useSpace({
    spaceId: space?.root_customer_space_id,
  });

  const treeData = useSpaceTree({
    spaceId: rootCustomerSpace?.id,
    parentSpaceId: rootCustomerSpace?.parent_id ?? null,
  });

  // Load expanded nodes from local storage if exists
  const [storedExpandedNodeIds] = useLocalStorage(
    'spacesTree.expandedNodes',
    null
  );
  // Set expanded nodes from local storage if exists, or use default 3 level expansion
  const initialExpandedNodeIds = useRef(
    storedExpandedNodeIds || expandNodesByDepth([treeData], 3)
  );

  if (!treeData) {
    return <Loader />;
  }

  return treeData ? (
    <Tree
      draggable={draggable}
      hideCreateCustomerSpaceButton={hideCreateCustomerSpaceButton}
      spaces={spaces}
      treeData={[treeData]}
      handleSelected={handleSelected}
      handleMoveSpace={noop}
      readonly={readonly}
      initialExpandedNodeIds={initialExpandedNodeIds.current}
      selected={selected?.id}
    />
  ) : null;
}
