import React from 'react';

export function lazyNamedImport<
  ImportFilePromiseResult extends Record<string, any>,
  ComponentName extends keyof ImportFilePromiseResult
>(
  dynamicImport: () => Promise<ImportFilePromiseResult>,
  componentName: ComponentName
) {
  return React.lazy(() =>
    dynamicImport().then((module) => ({
      default: module[componentName],
    }))
  );
}
