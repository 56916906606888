import React, { Dispatch, SetStateAction } from 'react';

import { SpaceType } from '@portals/api/organizations';

import { CustomerNodeContextMenu } from '../CustomerNodeContextMenu';
import { SpaceNodeContextMenu } from '../SpaceNodeContextMenu';

interface NodeContextMenuProps {
  branchType: SpaceType['space_type'];
  setIsContextMenuOpen: Dispatch<SetStateAction<boolean>>;
  nodeId: SpaceType['id'];
  setEditModeNodeId?: (nodeId: number | null) => void;
  onCreateSpace?: (parentId: number) => void;
  canDeleteCustomer: boolean;
}

export function NodeContextMenu({
  branchType,
  setIsContextMenuOpen,
  nodeId,
  setEditModeNodeId,
  onCreateSpace,
  canDeleteCustomer,
}: NodeContextMenuProps) {
  if (branchType === 'customer') {
    return (
      <CustomerNodeContextMenu
        setIsContextMenuOpen={setIsContextMenuOpen}
        nodeId={nodeId}
        onEditToggle={() => setEditModeNodeId?.(nodeId)}
        onCreateSpace={() => onCreateSpace?.(nodeId)}
        canDeleteCustomer={canDeleteCustomer}
      />
    );
  }

  return (
    <SpaceNodeContextMenu
      nodeId={nodeId}
      setIsContextMenuOpen={setIsContextMenuOpen}
      onEditToggle={() => setEditModeNodeId?.(nodeId)}
      onCreateSpace={() => onCreateSpace?.(nodeId)}
    />
  );
}
