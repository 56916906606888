import {
  Alert,
  Button,
  Modal,
  Stack,
  Text,
  TextInput,
  ModalProps as MantineModalProps,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { SpaceType, useDeleteSpace } from '@portals/api/organizations';
import { ModalFooter, NameAbbreviationAvatar } from '@portals/core';
import { ModalProps } from '@portals/framework';

export interface DeleteCustomerSpaceModalProps
  extends ModalProps<{
    customerName: string;
    spaceId: SpaceType['id'];
  }> {}

const CONFIRMATION_TEXT = 'I understand';

export function DeleteCustomerSpaceModal({
  closeMe,
  data: { customerName, spaceId },
}: DeleteCustomerSpaceModalProps) {
  const navigate = useNavigate();
  const deleteSpace = useDeleteSpace();

  const form = useForm({
    initialValues: {
      confirmation: '',
    },
  });

  const onSubmit = () => {
    deleteSpace.mutate(spaceId, {
      onSuccess: () => {
        closeMe();
        navigate('/overview/');
      },
    });
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      title="Delete Customer"
      padding="xxl"
      styles={modalStyles}
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="xxl">
          <Stack spacing="xxl" align="center">
            <Stack align="center">
              <NameAbbreviationAvatar
                name={customerName}
                size={85}
                radius={100}
              />

              <Text fz={28} data-testid="customer-name">
                {customerName}
              </Text>
            </Stack>

            <Alert ta="center" radius="lg" color="red" p="xl">
              <Text>
                Please note that deleting this customer is a permanent
                irreversible action. All associated data will be lost forever.
              </Text>

              <Text fw={600}>
                To proceed, type '{CONFIRMATION_TEXT}' below.
              </Text>
            </Alert>

            <TextInput
              w={250}
              data-autofocus
              placeholder="Type “I understand” to continue"
              {...form.getInputProps('confirmation')}
              data-testid="i-understand-text-input"
            />
          </Stack>

          <ModalFooter position="right" pt="xl">
            <Button
              variant="default"
              onClick={closeMe}
              data-testid="cancel-button"
            >
              Cancel
            </Button>

            <Button
              type="submit"
              disabled={form.values.confirmation !== CONFIRMATION_TEXT}
              data-testid="delete-customer-confirm-button"
            >
              Delete customer
            </Button>
          </ModalFooter>
        </Stack>
      </form>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    paddingRight: theme.spacing.xxl,
  },
});
