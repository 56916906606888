import {
  Box,
  Button,
  LoadingOverlay,
  Stack,
  TextInput,
  Text,
  createStyles,
  Anchor,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React, { useEffect } from 'react';
import { useMatch, useSearchParams } from 'react-router-dom';
import { object, string } from 'yup';

import { Pendo } from '@portals/analytics';
import { useVerifyEmail } from '@portals/api/auth';
import { dangerSrc } from '@portals/assets';
import {
  PasswordInputWithRequirements,
  yupPasswordValidator,
} from '@portals/framework';

import { StepSubtitle, StepTitle } from '../common';
import { useSignUpWizard, useTrackPageView } from '../wizard/wizard.hooks';

const schema = object({
  password: yupPasswordValidator,
  name: string().required('Name is required'),
});

export function SetUpAccount() {
  const { classes } = useStyles();

  const { onNext, onPrev, contextData, setContextData } = useSignUpWizard();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const verifyEmail = useVerifyEmail(token);

  useEffect(() => {
    if (verifyEmail.isSuccess) {
      setContextData((curr) => ({
        ...curr,
        email: verifyEmail.data.email,
      }));
    }
  }, [setContextData, verifyEmail.data?.email, verifyEmail.isSuccess]);

  const form = useForm({
    initialValues: {
      name: contextData.name,
      password: contextData.password,
    },
    validate: yupResolver(schema),
  });

  const onSubmit = (values: typeof form.values) => {
    setContextData((curr) => ({
      ...curr,
      name: values.name,
      password: values.password,
      auth: Boolean(contextData.name && contextData.password),
    }));

    onNext();
  };

  const match = useMatch('/auth/sign-up/:partner_name?');

  useTrackPageView('Set-up your account screen');

  if (verifyEmail.isLoading) {
    return <LoadingOverlay visible />;
  }

  if (verifyEmail.isError) {
    Pendo.track('email-verification-failed (V1)', {
      token,
      error: verifyEmail.error,
    });

    return (
      <Stack justify="center">
        <img src={dangerSrc} className={classes.danger} />

        <Text color="red.4" ta="center">
          Email verification failed
        </Text>

        <Anchor
          ta="center"
          href={`/auth/sign-up/${match?.params.partner_name}`}
        >
          Please sign up again
        </Anchor>
      </Stack>
    );
  }

  return (
    <Box>
      <StepTitle>Set-up your account</StepTitle>

      <StepSubtitle mb="xxl">Fill in your profile details.</StepSubtitle>

      <Stack spacing="lg">
        <form onSubmit={form.onSubmit(onSubmit)} noValidate>
          <Stack spacing="md">
            <TextInput
              data-testid="full-name-input"
              required
              label="Full name"
              placeholder="e.g. Thomas Parker"
              type="text"
              {...form.getInputProps('name')}
            />

            <Box data-testid="new-password-input">
              <PasswordInputWithRequirements
                {...form.getInputProps('password')}
              />
            </Box>

            <Button
              fullWidth
              mt="xl"
              type="submit"
              data-testid="onboarding-continue-button"
            >
              Continue
            </Button>

            <Button
              data-testid="back"
              data-analyticsid="back-to-authentication"
              fullWidth
              onClick={onPrev}
              variant="default"
              color="blue_gray"
              size="md"
            >
              Back
            </Button>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
}

const useStyles = createStyles(() => ({
  danger: {
    height: 115,
    width: 115,
    margin: 'auto',
  },
}));
