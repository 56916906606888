import { Box, Button, Group, Stack } from '@mantine/core';
import React, { FC } from 'react';

import { dangerSrc } from '@portals/assets';
import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps, useHubspotConversations } from '@portals/framework';

export const FailedOrder: FC<ModalProps<{ message?: string }>> = ({
  closeMe,
  data,
}) => {
  const { message } = data;
  const { toggleWidget } = useHubspotConversations();

  const onSupport = () => {
    toggleWidget();
    closeMe();
  };

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      title="Failed to Place Order"
      media={<img src={dangerSrc} />}
      description={
        <Stack spacing={0} data-testid="failed-order-modal-stack">
          <Box>
            {message ||
              'Your transaction has failed due to some technical error'}
          </Box>

          <Box>Please try again or contact support</Box>
        </Stack>
      }
      footer={
        <Group grow mt="md">
          <Button variant="default" onClick={onSupport}>
            Contact Support
          </Button>
          <Button
            onClick={closeMe}
            data-testid="close-failed-order-modal-button"
          >
            Close
          </Button>
        </Group>
      }
    />
  );
};
