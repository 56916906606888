import { IntegrationType } from '@portals/types';

import { globalQueryKeys } from '../global-query-keys';

export const CONNECTORS_API_URL = 'ui/organization/connectors';

export const SUPPORTED_C2C_CONNECTORS: Array<IntegrationType['name']> = [
  'mtr',
  'zoom',
  'xio',
  'q_sys',
  'bright_sign',
] as const;

export const connectorsQueryKeys = {
  base: [...globalQueryKeys.integrations, CONNECTORS_API_URL],
  all: () => [...connectorsQueryKeys.base, 'all'],
  details: (id: string) => [...connectorsQueryKeys.base, id],
};
