import { Group } from '@mantine/core';
import React from 'react';

import { SearchInput } from '@portals/core';
import { usePermissionAccess } from '@portals/framework';

import { CreateCustomerSpaceButton } from '../../../../../desktop/pages/overview/overview-sidebar/organization-tree/components/CreateCustomerSpaceButton';

interface TreeHeaderProps {
  handleSearchOnChange: (value: string) => void;
  searchString: string;
  hideCreateCustomerSpaceButton?: boolean;
}

export function TreeHeader({
  handleSearchOnChange,
  searchString,
  hideCreateCustomerSpaceButton = false,
}: TreeHeaderProps) {
  const { isAdmin } = usePermissionAccess();

  const displayCreateCustomButton = isAdmin && !hideCreateCustomerSpaceButton;

  return (
    <Group p="md" h={50}>
      <SearchInput
        value={searchString}
        placeholder="Find space / area..."
        onClear={() => handleSearchOnChange('')}
        onChange={(event) => handleSearchOnChange(event.target.value)}
        sx={{
          width: '100%',
        }}
      />

      {displayCreateCustomButton ? <CreateCustomerSpaceButton /> : null}
    </Group>
  );
}
