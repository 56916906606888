import {
  Button,
  Card,
  Group,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useState } from 'react';

import { ServerError } from '@portals/api';
import { useSetConnectorParams } from '@portals/api/organizations';
import { ModalProps } from '@portals/framework';
import { IntegrationType } from '@portals/types';

export interface QSysConnectorSettingsModalProps
  extends ModalProps<{
    connector: IntegrationType;
  }> {}

export function QSysConnectorSettingsModal({
  closeMe,
  data,
}: QSysConnectorSettingsModalProps) {
  const [error, setError] = useState<ServerError | null>(null);

  const setConnectorParams = useSetConnectorParams();
  const form = useForm({
    initialValues: {
      token: '',
    },
  });

  function onSubmit(values: typeof form.values) {
    setError(null);

    setConnectorParams.mutate(
      {
        connectorId: data.connector.id,
        connectorParams: values,
      },
      {
        onSuccess: () => {
          closeMe();
        },
        onError: (error) => {
          setError(error);
        },
      }
    );
  }

  return (
    <Modal
      radius="lg"
      onClose={closeMe}
      opened={true}
      size="lg"
      title="Q-SYS Reflect Authentication"
      styles={(theme) => ({
        content: {
          padding: theme.spacing.xxl,
        },
      })}
    >
      <LoadingOverlay visible={setConnectorParams.isLoading} />

      <Stack spacing="xxl">
        <Text size="sm">Enter your Q-SYS Reflect Third-Party API token.</Text>

        <form onSubmit={form.onSubmit(onSubmit)}>
          <Stack spacing="xxl">
            <TextInput
              w="100%"
              data-autofocus
              required
              {...form.getInputProps('token')}
              label="Q-Sys integration token"
              placeholder="Enter token"
            />

            {error && (
              <Text color="red" size="sm">
                {error.error}
              </Text>
            )}

            <Card bg="gray.1" p="xl" radius="lg">
              <Stack spacing="lg">
                <Text size="md">Creating a token in Q-Sys</Text>

                <Stack spacing="xs">
                  <Text size="sm" color="gray.8">
                    1. Open the Q-Sys Reflect
                  </Text>
                  <Text size="sm" color="gray.8">
                    2. Navigate to "Management" / "Organizations"
                  </Text>
                  <Text size="sm" color="gray.8">
                    3. Select your organization
                  </Text>
                  <Text size="sm" color="gray.8">
                    4. Navigate to "API Tokens"
                  </Text>
                  <Text size="sm" color="gray.8">
                    5. Click "+ New Token"
                  </Text>
                </Stack>
              </Stack>
            </Card>

            <Group position="right">
              <Button onClick={closeMe} variant="default">
                Cancel
              </Button>
              <Button type="submit">Authorize</Button>
            </Group>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
}
