import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useState } from 'react';

import { ServerError } from '@portals/api';
import { useSetConnectorParams } from '@portals/api/organizations';
import { ModalProps } from '@portals/framework';
import { IntegrationType } from '@portals/types';

export interface XiOConnectorSettingsModalProps
  extends ModalProps<{
    connector: IntegrationType;
  }> {}

export function XiOConnectorSettingsModal({
  closeMe,
  data,
}: XiOConnectorSettingsModalProps) {
  const [error, setError] = useState<ServerError | null>(null);
  const setConnectorParams = useSetConnectorParams();
  const form = useForm({
    initialValues: {
      account_id: '',
      subscription_key: '',
    },
  });

  function onSubmit(values: typeof form.values) {
    setError(null);

    setConnectorParams.mutate(
      {
        connectorId: data.connector.id,
        connectorParams: values,
      },
      {
        onSuccess: () => {
          closeMe();
        },
        onError: (error) => {
          setError(error);
        },
      }
    );
  }

  return (
    <Modal
      radius="lg"
      onClose={closeMe}
      opened
      size="xl"
      title="Crestron XiO Authentication"
      styles={(theme) => ({
        content: {
          padding: theme.spacing.xxl,
        },
      })}
    >
      <LoadingOverlay visible={setConnectorParams.isLoading} />

      <Stack>
        <Text size="sm">
          A Crestron XiO account with public API access is required to enable
          this connector.
        </Text>

        <form onSubmit={form.onSubmit(onSubmit)}>
          <Stack spacing="xxl">
            <Group noWrap>
              <TextInput
                w="100%"
                data-autofocus
                required
                {...form.getInputProps('account_id')}
                label="Account ID"
                placeholder="Enter ID"
              />

              <TextInput
                w="100%"
                required
                {...form.getInputProps('subscription_key')}
                label="Subscription Key"
                placeholder="Enter Key"
              />
            </Group>

            {error && (
              <Text color="red" size="sm">
                {error.error}
              </Text>
            )}

            <Group position="right">
              <Button onClick={closeMe} variant="default">
                Cancel
              </Button>
              <Button type="submit">Authorize</Button>
            </Group>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
}
