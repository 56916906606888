import { MantineTheme } from '@mantine/core';
import { Layout } from 'react-grid-layout';

import { CommandType, CustomColor } from '@portals/types';

import {
  BarControllerWidgetFormType,
  BarControllerWidgetProps,
} from './bar-controller';
import {
  BarIndicatorWidgetFormType,
  BarIndicatorWidgetProps,
} from './bar-indicator';
import { ButtonWidgetFormType, ButtonWidgetProps } from './button';
import {
  DeviceLocationWidgetFormType,
  DeviceLocationWidgetProps,
} from './device-location';
import {
  GaugeIndicatorWidgetFormType,
  GaugeIndicatorWidgetProps,
} from './gauge-indicator';
import {
  GroupedSwitchesWidgetFormType,
  GroupedSwitchesWidgetProps,
} from './grouped-switches';
import {
  NumericMetricWidgetFormType,
  NumericMetricWidgetProps,
} from './numeric-metric';
import {
  ScatterChartWidgetFormType,
  ScatterChartWidgetProps,
} from './scatter-chart';
import {
  SectionHeaderWidgetFormType,
  SectionHeaderWidgetProps,
} from './section-header';
import {
  SplineChartWidgetFormType,
  SplineChartWidgetProps,
} from './spline-chart';
import {
  StateControllerWidgetFormType,
  StateControllerWidgetProps,
} from './state-controller';
import { StatusWidgetFormType, StatusWidgetProps } from './status';
import { ToggleWidgetFormType, ToggleWidgetProps } from './toggle';
import { ValueWidgetFormType, ValueWidgetProps } from './value';
import { DeviceWidgetNameType } from '../device-widgets.types';

// Legacy widgets
export enum WidgetTypeEnum {
  LineChart = 'line_chart',
  PieChart = 'pie_chart',
  MetricWidget = 'metric_widget',
  GaugeWidget = 'gauge_widget',
  StatusWidget = 'status_widget',
  InsightsWidget = 'insights_widget',
}

// Legacy config
export interface WidgetConfigType {
  id: string;
  width: number;
  fields?: Record<string, any>;
  layout?: Record<string, number>;
}

// New widgets config
export interface NewWidgetConfigType {
  id: DeviceWidgetNameType;
  width: number;
  height?: number;
  fields?: CommonWidgetFormType;
  layout?: Partial<Layout>;
}

export interface UpdateLayoutPayload {
  id: string;
  width: number;
  height: number;
  layout: Layout;
}

export type WidgetType<
  TFields = Record<string, any>,
  TWidgetID = WidgetTypeEnum
> = {
  id?: string;
  name: string;
  config: {
    id: TWidgetID;
    fields: TFields;
    width: number;
    height: number | undefined;
    layout?: Partial<Layout>;
  };
  colors?: string[];
  onChangeColor?: (
    color: string,
    colorHex: MantineTheme['colors'][string]
  ) => void;
};

export interface NewWidgetType<TForm = CommonWidgetFormType>
  extends WidgetType<TForm, DeviceWidgetNameType> {}

export interface DeviceWidgetsSectionType<TWidget = NewWidgetType> {
  id: string;
  name: string;
  widgets: TWidget[];
  condition?: {
    path?: string;
    isLabOnly: boolean;
  };
}

export interface WidgetWithLayout extends NewWidgetType {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  name: string;
  minH: number;
  minW: number;
}

export type CommonWidgetFormType =
  | StatusWidgetFormType
  | NumericMetricWidgetFormType
  | ToggleWidgetFormType
  | ButtonWidgetFormType
  | GaugeIndicatorWidgetFormType
  | DeviceLocationWidgetFormType
  | GroupedSwitchesWidgetFormType
  | BarIndicatorWidgetFormType
  | SectionHeaderWidgetFormType
  | StateControllerWidgetFormType
  | SplineChartWidgetFormType
  | ValueWidgetFormType
  | BarControllerWidgetFormType
  | ScatterChartWidgetFormType;

export interface WidgetPropsMapType {
  status: StatusWidgetProps;
  numeric_metric: NumericMetricWidgetProps;
  spline_chart: SplineChartWidgetProps;
  bar_indicator: BarIndicatorWidgetProps;
  gauge_indicator: GaugeIndicatorWidgetProps;
  toggle: ToggleWidgetProps;
  button: ButtonWidgetProps;
  device_location: DeviceLocationWidgetProps;
  grouped_switches: GroupedSwitchesWidgetProps;
  bar_controller: BarControllerWidgetProps;
  section_header: SectionHeaderWidgetProps;
  state_controller: StateControllerWidgetProps;
  value: ValueWidgetProps;
  scatter_chart: ScatterChartWidgetProps;
}

export type WidgetIconNameType =
  | 'battery_full'
  | 'blur'
  | 'box'
  | 'call'
  | 'cloud_connection'
  | 'driver'
  | 'electricity'
  | 'eye_slash'
  | 'eye'
  | 'flash'
  | 'global'
  | 'gpf'
  | 'key'
  | 'lifebuoy'
  | 'location'
  | 'lock'
  | 'map'
  | 'microphone'
  | 'mirroring_screen'
  | 'music'
  | 'notification'
  | 'radar_2'
  | 'radar'
  | 'refresh'
  | 'setting'
  | 'shield_cross'
  | 'shield_tick'
  | 'smart_car'
  | 'status'
  | 'video'
  | 'volume_cross'
  | 'volume_low'
  | 'volume_slash'
  | 'lamp_charge'
  | 'bluetooth'
  | 'wifi'
  | 'note-2'
  | 'scan-barcode'
  | 'barcode'
  | 'password-check'
  | 'element-3'
  | 'glass'
  | 'document-text'
  | 'code'
  | 'danger'
  | 'tick-circle'
  | 'flag'
  | 'share'
  | '24-support'
  | 'cpu'
  | 'monitor'
  | 'clock'
  | 'calendar'
  | 'simcard';

export type WidgetColorType =
  | 'deep_purple_accent.2'
  | 'indigo_accent.3'
  | 'blue_accent.4'
  | 'purple_accent.2'
  | 'pink_accent.2'
  | 'red.4'
  | 'orange.6'
  | 'yellow.6'
  | 'lime_accent.4'
  | 'teal_accent.3'
  | 'cyan.4'
  | 'cyan_accent.4'
  | 'gray.3'
  | 'teal_accent.4'
  // Gray
  | 'gray.9'
  | 'gray.8'
  | 'gray.7'
  | 'gray.6'
  | 'gray.5'
  | 'gray.4'
  | 'gray.2'
  | 'gray.1'
  | 'gray.0'
  | 'white'
  | string;

export type NumberFormatType = 'none' | 'decimal' | 'scientific';

export type ScaleType = 'linear' | 'log';

export type MeasurementFormType = 'static' | 'dynamic';

export type WidgetFormToPreviewConverterCb<
  TWidgetForm,
  TWidgetProps,
  TParams = Record<string, any>
> = (params: { formValues: TWidgetForm } & Partial<TParams>) => TWidgetProps;

export interface ConvertWidgetFormToWidgetProps {
  status: WidgetFormToPreviewConverterCb<
    StatusWidgetFormType,
    StatusWidgetProps
  >;
  numeric_metric: WidgetFormToPreviewConverterCb<
    NumericMetricWidgetFormType,
    NumericMetricWidgetProps
  >;
  spline_chart: WidgetFormToPreviewConverterCb<
    SplineChartWidgetFormType,
    SplineChartWidgetProps
  >;
  scatter_chart: WidgetFormToPreviewConverterCb<
    ScatterChartWidgetFormType,
    ScatterChartWidgetProps
  >;
  bar_indicator: WidgetFormToPreviewConverterCb<
    BarIndicatorWidgetFormType,
    BarIndicatorWidgetProps,
    { value: number }
  >;
  gauge_indicator: WidgetFormToPreviewConverterCb<
    GaugeIndicatorWidgetFormType,
    GaugeIndicatorWidgetProps
  >;
  toggle: WidgetFormToPreviewConverterCb<
    ToggleWidgetFormType,
    ToggleWidgetProps
  >;
  button: WidgetFormToPreviewConverterCb<
    ButtonWidgetFormType,
    ButtonWidgetProps
  >;
  device_location: WidgetFormToPreviewConverterCb<
    DeviceLocationWidgetFormType,
    DeviceLocationWidgetProps
  >;
  grouped_switches: WidgetFormToPreviewConverterCb<
    GroupedSwitchesWidgetFormType,
    GroupedSwitchesWidgetProps,
    {
      onToggle: (
        commandId: string,
        commandParamKey: string,
        isChecked: boolean
      ) => void;
      deviceState: Record<string, any>;
    }
  >;
  section_header: WidgetFormToPreviewConverterCb<
    SectionHeaderWidgetFormType,
    SectionHeaderWidgetProps
  >;
  state_controller: WidgetFormToPreviewConverterCb<
    StateControllerWidgetFormType,
    StateControllerWidgetProps,
    { onToggle: (option: string) => void }
  >;
  value: WidgetFormToPreviewConverterCb<ValueWidgetFormType, ValueWidgetProps>;
  bar_controller: WidgetFormToPreviewConverterCb<
    BarControllerWidgetFormType,
    BarControllerWidgetProps,
    {
      value: number;
      onChange: (value: number) => void;
      isLoading?: boolean;
    }
  >;
}

export interface ActiveCommandType extends CommandType {
  order: number;
}

export type OnAddCustomColorFn = (customColor: CustomColor) => void;
