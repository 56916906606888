import {
  filter,
  flow,
  groupBy,
  includes,
  isArray,
  isEmpty,
  map,
  reduce,
  startsWith,
  toLower,
} from 'lodash/fp';
import { EventDataNode } from 'rc-tree/lib/interface';

import { SpaceTreeNodeType, SpaceType } from '@portals/api/organizations';

export function searchTree(
  node: SpaceTreeNodeType,
  matchingId: number | null
): SpaceTreeNodeType | null {
  if (node.id === matchingId) {
    return node;
  } else if (node.children != null) {
    let result = null;

    for (let i = 0; result == null && i < node.children.length; i++) {
      result = searchTree(node.children[i], matchingId);
    }
    return result;
  }

  return null;
}

export function generateUniqueDefaultSpaceNameInSiblings(
  nodes: Array<SpaceTreeNodeType>
): string {
  const DEFAULT_NAME = 'Untitled space';
  const takenNames = new Set(
    flow([
      filter<SpaceTreeNodeType>((node) => {
        return startsWith('Untitled space', node.name);
      }),
      map((space: SpaceType) => space.name),
    ])(nodes)
  );

  if (!takenNames.size) return DEFAULT_NAME;

  for (let index = 0; index <= takenNames.size; index++) {
    const potentialName = `Untitled space ${index + 1}`;

    if (!takenNames.has(potentialName)) {
      return potentialName;
    }
  }

  return DEFAULT_NAME;
}

export function expandNodesByDepth(
  nodeList: Array<SpaceTreeNodeType>,
  maxDepth: number
): Array<number> {
  if (!nodeList || maxDepth === 0) {
    return [];
  }

  const expandedNodes: Array<number> = [];

  function iterateOverNodes(nodes: Array<SpaceTreeNodeType>, depth: number) {
    nodes.forEach((node) => {
      if (!isEmpty(node.children)) {
        expandedNodes.push(node.id);

        if (depth > 0) {
          iterateOverNodes(node.children, depth - 1);
        }
      }
    });
  }

  iterateOverNodes(nodeList, maxDepth);

  return expandedNodes;
}

function getChildren(
  parent_id: SpaceType['parent_id'],
  grouped: Record<
    number | string, // string is only for `null` used as the key for the root node(s) who's parent_id is null
    Array<SpaceType>
  >
): Array<SpaceTreeNodeType> {
  return (grouped[parent_id as number | string] || []).map((space) => ({
    key: space.id,
    id: space.id,
    parent_id,
    name: space.name,
    access_level: space.access_level,
    title: space.name,
    lowerCaseTitle: toLower(space.name),
    path: [...(space.path || []), space.id],
    position: space.position,
    incidents: space.state?.incidents,
    device_count: space.state?.devices,
    online_devices: space.state?.online_devices,
    local_device_count: space.state?.local_devices,
    local_online_devices: space.state?.local_online_devices,
    local_incidents: space.state?.local_incidents,
    space_type: space.space_type,
    root_customer_space_id: space.root_customer_space_id,
    children: getChildren(space.id, grouped),
  }));
}

export function buildTreeFromNodes(
  nodes: Array<SpaceType>,
  parentId: SpaceType['parent_id'] = null
) {
  const grouped: Record<
    number | string, // string is only for `null` used as the key for the root node(s) who's parent_id is null
    Array<SpaceType>
  > = groupBy((item) => item.parent_id, nodes);

  return getChildren(parentId, grouped);
}

export function filterTreeNodes(
  treeNodes: Array<SpaceTreeNodeType>,
  filterTerm: string
) {
  const lowerCaseFilterTerm = toLower(filterTerm);

  const getNodes = (
    result: Array<SpaceTreeNodeType>,
    node: SpaceTreeNodeType
  ) => {
    if (includes(lowerCaseFilterTerm, node.lowerCaseTitle)) {
      result.push(node);

      return result;
    }

    if (isArray(node.children)) {
      const children = reduce(getNodes, [], node.children);

      if (children.length) result.push({ ...node, children });
    }

    return result;
  };

  return reduce(getNodes, [], treeNodes);
}

export function getAllNodesIds(
  treeData: Array<EventDataNode<SpaceTreeNodeType>> | Array<SpaceTreeNodeType>
) {
  const getNodes = (result: Array<number>, node: SpaceTreeNodeType) => {
    result.push(node.id);

    if (isArray(node.children)) {
      const childrenIds: Array<number> = reduce(getNodes, [], node.children);

      if (childrenIds.length) result.push(...childrenIds);
    }

    return result;
  };

  return reduce(getNodes, [], treeData);
}
